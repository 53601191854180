import { DeleteOutline, Edit } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useCompany } from "../../components/context/CompanyContext";
import Loading from "../../components/loading/loading";
import "../../global.css";
import { userRequest } from "../../requestMethods";
import { formatTimeDDMMYYY } from "../../util/helper";
import Switch from "@mui/material/Switch";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

export default function UserList() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const { companyId } = useCompany();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUsers = async () => {
    try {
      let url = "";

      setIsLoading(true);
      if (currentUser.isAdmin) {
        url = `${companyId}/users`;
      } else {
        url = `${companyId}/users/find/${currentUser.userId}`;
      }

      const res = await userRequest.get(url);

      setData(res.data);
    } catch (error) {
      console.error(error.code);
      if (error.code === 403) {
        nav(`../login`);
      }
    }
    setIsLoading(false);
  };

  const deleteUsr = async (usr, del) => {
    //const res = await userRequest.delete(`${companyId}/users/${usr}`);

    let delToogle = { deleted: null };
    if (del) {
      delToogle = { deleted: new Date(), lastUserUpdate: currentUser.userId };
    }

    const res = await userRequest.put(`${companyId}/users/${usr}`, delToogle);

    if (res.data > 0) {
      getUsers();
    } else {
      Swal.fire("Atenção!", "Erro ao inativar usuário!", "error");
    }
  };
  const onButtonDeleteClick = (e, row) => {
    if (currentUser.isAdmin) {
      if (!e.target.checked) {
        Swal.fire({
          title: "Tem certeza que deseja inativar?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#FF385C",
          confirmButtonText: "Sim, inativar!",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            deleteUsr(row, true);
          } else {
            getUsers();
          }
        });
      } else {
        deleteUsr(row, false);
      }
    } else {
      Swal.fire(
        "Atenção!",
        "Você não tem permissão para inativar usuário!",
        "warning"
      );
    }
  };

  const onButtonEditarClick = (e, row) => {
    nav(window.location.pathname.replace("tabconfigs", "users") + "/" + row);
  };

  const nav = useNavigate();
  const addRow = () => {
    nav(`../users/new`);
  };

  const columns = [
    {
      field: "userId",
      headerName: "Cod. Usuário",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "editar",
      headerName: "",
      width: 80,
      align: "center",
      headerClassName: "dataGridMuiHeader",
      headerAlign: "center",
      display: "flex",
      renderCell: (params) => {
        return (
          <>
            {currentUser.isAdmin ? (
              <IconButton
                onClick={(e) => onButtonEditarClick(e, params.row.userId)}
              >
                <EditIcon />
              </IconButton>
            ) : (
              false
            )}
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Nome",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      align: "left",
      headerAlign: "left",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "contact",
      headerName: "Contato",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "position",
      headerName: "Cargo",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "username",
      headerName: "Nome de Usuário",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "cpf",
      headerName: "CPF",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "isAdmin",
      headerName: "Administrador",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "createdAt",
      headerName: "Criada em",
      width: 200,
      align: "left",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
    },
    {
      field: "updatedAt",
      headerName: "Atualizada em",
      width: 200,
      align: "left",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatTimeDDMMYYY(value)}`;
      },
      valueGetter: (value) => value && new Date(value),
    },
    // {
    //   field: "deleted",
    //   headerName: "",
    //   width: 80,
    //   align: "center",
    //   headerClassName: "dataGridMuiHeader",
    //   headerAlign: "center",
    //   renderCell: (params) => {
    //     // eslint-disable-next-line default-case
    //     switch (params.value) {
    //       case "S":
    //         return (
    //           <>
    //             <CheckCircleOutlineOutlinedIcon color="success" />
    //           </>
    //         );
    //       case "N":
    //         return (
    //           <>
    //             <RemoveCircleOutlineOutlinedIcon sx={{ color: "red" }} />
    //           </>
    //         );
    //       case "P":
    //         return (
    //           <>
    //             <ReportGmailerrorredIcon sx={{ color: "#FF385C" }} />
    //           </>
    //         );
    //     }
    //   },
    // },
    {
      field: "deleted",
      headerName: "",
      width: 80,
      align: "center",
      headerClassName: "dataGridMuiHeader",
      headerAlign: "center",
      display: "flex",
      renderCell: (params) => {
        if (currentUser.isAdmin) {
          if (!params.row.deleted) {
            return (
              <Switch
                defaultChecked={true}
                onChange={(e) => onButtonDeleteClick(e, params.row.userId)}
              />
            );
          } else {
            return (
              <Switch
                defaultChecked={false}
                onChange={(e) => onButtonDeleteClick(e, params.row.userId)}
              />
            );
          }
        }
      },
    },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  return (
    <div className="div_custom_">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_Container">
          <div className="div_custom_UpdateRight">
            <br></br>
            <Button
              className="custom_add_top"
              variant="outlined"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={addRow}
              size="small"
            >
              Adicionar Usuário
            </Button>
          </div>
          <div className="div_custom_UpdateLeft">
            <div style={{ height: 900 }}>
              <DataGridPremium
                autoHeight
                sx={{
                  fontFamily: "Manrope",
                  fontWeight: "500",
                  border: "0px",
                }}
                rowHeight={45}
                localeText={
                  ptBRCore.components.MuiDataGrid.defaultProps.localeText
                }
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                  panel: {
                    sx: {
                      top: "15px !important",
                      left: "50px !important",
                      position: "fixed !important",
                    },
                  },
                }}
                getRowId={(row) => row.userId}
                rows={data}
                disableSelectionOnClick
                columns={columns}
                pagination
                autoPageSize
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
