import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useCompany } from "../../components/context/CompanyContext";
import catalogo from "../../icons/catalogo.svg";
import cliente from "../../icons/cliente.svg";
import valor_medio from "../../icons/valor_medio.svg";
import { userRequest } from "../../requestMethods";
import { formatTimeDDMMYYY, retunrMonth } from "../../util/helper";
import "./report.css";
import FormControl from "@mui/material/FormControl";
import { MultiSelect } from "react-multi-select-component";

export default function HomeReport() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const { companyId } = useCompany();
  const [readonly, setReadonly] = useState();
  const [ordersMonth, setOrdersMonth] = useState();
  const [avgOrdersDay, setAvgOrdersDay] = useState();
  const [ordersDay, setOrdersDay] = useState();
  const [ordersUser, setOrdersUser] = useState([]);
  const [avgOrdersUser, setAvgOrdersUser] = useState([]);
  const [stories, setStories] = useState([]);
  const [dataIni, setDateIni] = useState();
  const [dataFim, setDateFim] = useState();
  const [storeIds, setStoreIds] = useState([]);
  const [selected, setSelected] = useState([]);
  const nav = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setReadonly("7");
    if (!currentUser) {
      nav(`../login`);
    }

    if (companyId > 0) {
      getStories();
      getOrdersbyMonth();
      getAvgOrdersbyDay();
      getOrdersbyDay();
      getOrdersbyUser();
      getAvgOrdersbyUser();
    }
    setDateIni(dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD"));
    setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (companyId > 0) {
      getOrdersbyMonth();
      getAvgOrdersbyDay();
      getOrdersbyDay();
      getOrdersbyUser();
      getAvgOrdersbyUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataIni, dataFim, storeIds]);

  useEffect(() => {
    if (selected.length === stories.length) {
      return setStoreIds([]);
    }
    setStoreIds(selected.map((item) => item.value));
  }, [selected]);

  const getOrdersbyUser = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportOrderPays/ordersuser`,
          obj
        );

        setOrdersUser(
          res.data.map((order) => ({
            Total: order.total,
            managedBy: order.managedBy,
          }))
        );
      }
    } catch {}
  };
  const getAvgOrdersbyUser = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportOrderPays/avgordersuser`,
          obj
        );

        setAvgOrdersUser(
          res.data.map((order) => ({
            Total: order.total,
            managedBy: order.managedBy,
          }))
        );
      }
    } catch {}
  };

  const getOrdersbyDay = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        stories: storeIds,
      };
      if (!currentUser.isAdmin) {
        obj = {
          start: dataIni,
          end: dataFim,
          userId: currentUser.userId,
          stories: storeIds,
        };
      }

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportOrderPays/ordersday`,
          obj
        );

        setOrdersDay(
          res.data.map((order) => ({
            criado: formatTimeDDMMYYY(order.createdAt),
            Total: order.amount,
          }))
        );
      }
    } catch {}
  };
  const getOrdersbyMonth = async () => {
    try {
      const res = await userRequest.post(
        `${companyId}/reportOrderPays/ordersmonth`,
        { stories: storeIds }
      );

      setOrdersMonth(
        res.data.map((order) => ({
          criado: retunrMonth(order.createdAt),
          Total: order.amount,
        }))
      );
    } catch {}
  };

  const getAvgOrdersbyDay = async () => {
    try {
      const res = await userRequest.post(
        `${companyId}/reportOrderPays/avgordersday`,
        { stories: storeIds }
      );

      setAvgOrdersDay(
        res.data.map((order) => ({
          criado: retunrMonth(order.createdAt),
          Total: order.amount,
        }))
      );
    } catch {}
  };
  const getStories = async () => {
    const res = await userRequest.get(`${companyId}/store/stories`);
    setStories(
      res.data.map((item) => ({
        label: item.store,
        value: item.storeId,
      }))
    );
  };

  const handleChangeStart = (e) => {
    setDateIni(e.target.value);
  };
  const handleChangeEnd = (e) => {
    setDateFim(e.target.value);
  };
  const handleChange = (event) => {
    setReadonly(event.target.value);
    // eslint-disable-next-line default-case
    switch (event.target.value) {
      case "0":
        setDateIni(dayjs(new Date()).format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "7":
        setDateIni(dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "15":
        setDateIni(dayjs(new Date()).add(-15, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "30":
        setDateIni(dayjs(new Date()).add(-30, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
    }
  };

  const _voltarTopo = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="div_home_Container">
      <table width="100%" className="tableSpaceTab3">
        <tbody>
          <tr align="right">
            <FormControl sx={{ minWidth: 300 }} size="small">
              <MultiSelect
                overrideStrings={{
                  selectAll: "Todos",
                  search: "Buscar",
                  selectSomeItems: "Lojas",
                  allItemsAreSelected: "Todas lojas selecionadas.",
                  noOptions: "Nehuma loja",
                }}
                options={stories}
                value={selected}
                onChange={setSelected}
                labelledBy="Lojas"
              />
            </FormControl>
          </tr>
        </tbody>
      </table>
      <table width="100%" className="tableSpaceTab2">
        <tbody>
          <tr>
            <td width="50%">
              <div className="div_home_custom">
                <table className="table">
                  <tbody>
                    <tr>
                      <td align="left" width="100%">
                        <tr>
                          <td>
                            <img src={catalogo} />
                          </td>
                          <td>
                            <tr className="labelTitle">
                              Histórico mensal de vendas
                            </tr>
                            <tr className="labelMsgData">
                              Exibindo dados do primeiro dia do ano até hoje
                            </tr>
                          </td>
                        </tr>
                      </td>
                      <td align="left" width="0%">
                        {" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br></br>
                <ResponsiveContainer width="95%" height={250}>
                  <BarChart data={ordersMonth}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis
                      type="number"
                      tick={{ fontSize: 12 }}
                      tickFormatter={(tick) => {
                        return tick.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        });
                      }}
                    />
                    <Tooltip
                      formatter={(value) => {
                        return value.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        });
                      }}
                    />
                    <XAxis
                      type="category"
                      interval={0}
                      angle={-40}
                      dataKey="criado"
                      tick={{ fontSize: 12 }}
                    />
                    <Bar dataKey="Total" fill="#ff385c" barSize={5} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </td>
            <td>
              <div className="div_home_custom">
                <table className="table">
                  <tbody>
                    <tr>
                      <td align="left" width="100%">
                        <tr>
                          <td>
                            <img src={valor_medio} />
                          </td>
                          <td>
                            <tr className="labelTitle">
                              Valor médio mensal de vendas
                            </tr>
                            <tr className="labelMsgData">
                              Exibindo dados do primeiro dia do ano até hoje
                            </tr>
                          </td>
                        </tr>
                      </td>
                      <td align="left" width="0%">
                        {" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br></br>
                <ResponsiveContainer width="95%" height={250}>
                  <BarChart data={avgOrdersDay}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis
                      type="number"
                      tick={{ fontSize: 12 }}
                      tickFormatter={(tick) => {
                        return tick.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        });
                      }}
                    />
                    <Tooltip
                      formatter={(value) => {
                        return value.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        });
                      }}
                    />
                    <XAxis
                      type="category"
                      interval={0}
                      angle={-40}
                      dataKey="criado"
                      tick={{ fontSize: 12 }}
                    />
                    <Bar dataKey="Total" fill="#ff385c" barSize={5} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <div className="datePicker">
          <label className="labelPeriodo">
            Filtrar:
            <input
              type="date"
              id="startDate"
              value={dataIni}
              name="trip-start"
              min="2023-01-01"
              onChange={handleChangeStart}
              disabled={readonly !== "F"}
            />
            <input
              type="date"
              id="endDate"
              value={dataFim}
              name="trip-start"
              min="2023-01-01"
              onChange={handleChangeEnd}
              disabled={readonly !== "F"}
            />
          </label>
        </div>
        <div className="column">
          <input
            type="radio"
            id="30"
            value="30"
            onChange={handleChange}
            checked={readonly === "30"}
          />
          <label for="30">30 dias</label>
          <input
            type="radio"
            id="15"
            value="15"
            onChange={handleChange}
            checked={readonly === "15"}
          />
          <label for="15">15 dias</label>
          <input
            type="radio"
            id="7"
            value="7"
            onChange={handleChange}
            checked={readonly === "7"}
          />
          <label for="7">7 dias</label>
          <input
            type="radio"
            id="0"
            value="0"
            onChange={handleChange}
            checked={readonly === "0"}
          />
          <label for="0">Hoje</label>
          <input
            type="radio"
            id="0"
            value="F"
            onChange={handleChange}
            checked={readonly === "F"}
          />
          <label for="0">Filtro</label>
        </div>
      </div>
      <table width="100%" className="tableSpaceTab">
        <tbody>
          <tr>
            <div className="div_home_custom">
              <table className="table">
                <tbody>
                  <tr>
                    <td align="left" width="100%">
                      <tr>
                        <td>
                          <img src={valor_medio} />
                        </td>
                        <td>
                          <tr className="labelTitle">
                            Evolução diária de vendas
                          </tr>
                          <tr className="labelMsgData">
                            {readonly === "F"
                              ? "Exibindo dados do filtro de data"
                              : readonly === "0"
                              ? "Exibindo dados de hoje"
                              : "Exibindo dados dos últimos " +
                                readonly +
                                " dias"}
                          </tr>
                        </td>
                      </tr>
                    </td>
                    <td align="left" width="0%">
                      {" "}
                    </td>
                  </tr>
                </tbody>
              </table>
              <br></br>
              <ResponsiveContainer width="100%" height={250}>
                <AreaChart
                  data={ordersDay}
                  height={250}
                  margin={{ top: 0, right: 0, left: 30, bottom: 0 }}
                >
                  <XAxis
                    dataKey="criado"
                    stroke="#ff385c"
                    tick={{ fontSize: 12 }}
                  />
                  <Line type="monotone" dataKey={"Total"} stroke="#ff385c" />
                  <YAxis
                    tick={{ fontSize: 12 }}
                    tickFormatter={(tick) => {
                      return tick.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      });
                    }}
                  />
                  <Tooltip
                    formatter={(value) => {
                      return value.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      });
                    }}
                  />
                  <Area
                    type="monotone"
                    dataKey={"Total"}
                    stroke="#ff385c"
                    fill="#ff385c"
                  />
                  <CartesianGrid stroke="#e0dfdf" strokeDasharray="5 5" />
                </AreaChart>
              </ResponsiveContainer>{" "}
            </div>
          </tr>
        </tbody>
      </table>
      <table width="100%" className="tableSpaceTab2">
        <tbody>
          <tr>
            <td width="50%">
              <div className="div_home_custom">
                <table className="table">
                  <tbody>
                    <tr>
                      <td align="left" width="100%">
                        <tr>
                          <td>
                            <img src={cliente} />
                          </td>
                          <td>
                            <tr className="labelTitle">
                              Vendas por vendedor(a)
                            </tr>
                            <tr className="labelMsgData">
                              {readonly === "F"
                                ? "Exibindo dados do filtro de data"
                                : readonly === "0"
                                ? "Exibindo dados de hoje"
                                : "Exibindo dados dos últimos " +
                                  readonly +
                                  " dias"}
                            </tr>
                          </td>
                        </tr>
                      </td>
                      <td align="left" width="0%">
                        {" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br></br>
                <ResponsiveContainer width="95%" height={250}>
                  <BarChart data={ordersUser}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis
                      type="number"
                      tick={{ fontSize: 12 }}
                      tickFormatter={(tick) => {
                        return tick.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        });
                      }}
                    />
                    <Tooltip
                      formatter={(value) => {
                        return value.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        });
                      }}
                    />
                    <XAxis
                      type="category"
                      interval={0}
                      angle={-40}
                      dataKey="managedBy"
                      tick={{ fontSize: 12 }}
                    />
                    <Bar dataKey="Total" fill="#ff385c" barSize={5} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </td>
            <td>
              <div className="div_home_custom">
                <table className="table">
                  <tbody>
                    <tr>
                      <td align="left" width="100%">
                        <tr>
                          <td>
                            <img src={cliente} />
                          </td>
                          <td>
                            <tr className="labelTitle">
                              Venda média por vendedor(a)
                            </tr>
                            <tr className="labelMsgData">
                              {readonly === "F"
                                ? "Exibindo dados do filtro de data"
                                : readonly === "0"
                                ? "Exibindo dados de hoje"
                                : "Exibindo dados dos últimos " +
                                  readonly +
                                  " dias"}
                            </tr>
                          </td>
                        </tr>
                      </td>
                      <td align="left" width="0%">
                        {" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br></br>
                <ResponsiveContainer width="95%" height={250}>
                  <BarChart data={avgOrdersUser}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis
                      type="number"
                      tick={{ fontSize: 12 }}
                      tickFormatter={(tick) => {
                        return tick.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        });
                      }}
                    />
                    <Tooltip
                      formatter={(value) => {
                        return value.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        });
                      }}
                    />
                    <XAxis
                      type="category"
                      interval={0}
                      angle={-40}
                      dataKey="managedBy"
                      tick={{ fontSize: 12 }}
                    />
                    <Bar dataKey="Total" fill="#ff385c" barSize={5} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="divRodape" onClick={_voltarTopo}>
        voltar ao topo
      </div>
    </div>
  );
}
