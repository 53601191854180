import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";
import Company from "../company/company";
import UserList from "../user/userList";
import "./tabDashboard.css";
import { useDispatch, useSelector } from "react-redux";
import { titleSetRef } from "../../redux/titleRedux";
import StoreList from "../store/storeList";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabImport() {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();

  dispatch(titleSetRef({ title: "Configurações" }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      minWidth: 0,
      [theme.breakpoints.up("sm")]: {
        minWidth: 0,
      },
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      color: "rgba(0, 0, 0, 0.85)",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "#FF385C",
        opacity: 1,
      },
      "&.Mui-selected": {
        color: "#FF385C",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&.Mui-focusVisible": {
        backgroundColor: "#d1eaff",
      },
    })
  );
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider" }}
        id="box-header-dash"
      >
        <Tabs value={value} onChange={handleChange}>
          <AntTab label="Usuários" {...a11yProps(0)} />
          <AntTab label="Empresa" {...a11yProps(1)} />
          <AntTab label="Lojas" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <UserList />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Company />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <StoreList />
      </CustomTabPanel>
    </Box>
  );
}
