import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import SidebarComponent from "./components/SidebarComponent";
import Topbar from "./components/Topbar";
import Login from "./pages/login/login";
import Home from "./pages/reports/home";
import TabConfig from "./pages/tabs/TabConfig";
import User from "./pages/user/user";
import UserList from "./pages/user/userList";
import { useCompany } from "../src/components/context/CompanyContext";
import PaymentMoodaReport from "./pages/reports/paymentMoodaReport";
import OrderList from "./pages/order/orderList";

const Layout = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const currentSlug = useSelector((state) => state.urlRef.currentSlug);

  const { companyId, slug } = useCompany();
  useEffect(() => {
    if (currentUser) {
      const [_ignoreFirstSlash, rootParam] =
        window.location.pathname.split("/");

      if (
        rootParam !== "home" &&
        rootParam !== (currentSlug && currentSlug.slug)
      ) {
        var tela = "/login";
        localStorage.clear();

        var split = window.location.pathname.toString().split("/");
        var newUrl = window.location.origin + "/" + split[1] + tela;
        window.location.href = newUrl;
      }
    }
  }, [currentUser, companyId]);

  return (
    <div class="wrapper">
      {currentUser && Object.keys(currentUser).length > 0 ? (
        <header>
          <Topbar />
        </header>
      ) : (
        false
      )}
      <article>
        <Outlet />
      </article>
      {currentUser && Object.keys(currentUser).length > 0 ? (
        <aside>
          <SidebarComponent />
        </aside>
      ) : (
        false
      )}
    </div>
  );
};

const router = createBrowserRouter([
  // {
  //   path: "/onboarding",
  //   element: <Onboarding />,
  // },
  {
    path: "/:companyId/login",
    element: <Login />,
  },
  {
    path: "/:companyId",
    element: <Layout />,
    children: [
      {
        path: "/:companyId/home",
        element: <Home />,
      },
      {
        path: "/:companyId/users",
        element: <UserList />,
      },
      {
        path: "/:companyId/users/:userId",
        element: <User />,
      },
      {
        path: "/:companyId/tabconfigs",
        element: <TabConfig />,
      },
      {
        path: "/:companyId/paymentReport",
        element: <PaymentMoodaReport />,
      },
      {
        path: "/:companyId/orders",
        element: <OrderList />,
      },
    ],
  },
]);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
